@import url('https://fonts.googleapis.com/css2?family=Kreon:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kenia&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}

.bg-nav {
  background:rgba(255, 255, 255, 0.10);

}
